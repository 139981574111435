.right_main_section {
    width: 100%;
}

.exh-form-control::placeholder,
.exh-form-control,
.exh-form-control:focus {
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.card_dark {
    background-color: #00000054;
    border-radius: 20px;
    padding: 30px;
    position: relative;

}

.addLiq .card_dark.add_form {
    padding-bottom: 50px;
}

.exchangePg .card_dark {
    padding: 20px;
}

.pad30 {
    padding: 30px;
}

.card_dark .title_div {
    display: -webkit-inline-box;
}

.card_dark .title_div h2 {
    color: white;
    font-size: 19px;
    line-height: 13.2px;
    margin-bottom: 20px;
    font-family: interMedium;
}

.setting_icon {
    position: relative;
    right: 0px;
}

.d-flex {
    display: flex !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.form-div {
    background-color: #090A0B;
    border-radius: 38px;
    padding: 15px 30px;
}

.bridge-box {
    background: #181E27 !important;
}

.network-box {
    border: 2px solid rgba(53, 94, 239, 0.51) !important;
    border-radius: 19px !important;
}

.networkmodal {
    background: rgba(10, 9, 16, 1);
    border: 2px solid rgba(53, 94, 239, 0.51);
    border-radius: 33px;
    width: 100%;
    padding:  25px;
    backdrop-filter: blur(200px) !important;
}

.align-items-center {
    align-items: center !important;
}

.input::placeholder,
.input,
.input:focus,
.exh-form-control:placeholder {
    color: #3FA9F5;
    opacity: 1;
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.token-name:placeholder {
    color: #E5E5E5 !important;
}

.input {
    background: none !important;
    border: none !important;
}

.tip_text {
    color: #CECECE;
    line-height: 24px;
    font-weight: 400;
}

input.exh-form-control::-webkit-input-placeholder {
    /* Edge */
    color: #7C95FE;
    opacity: 1;
}

input.exh-form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #7C95FE;
    opacity: 1;
}

input.exh-form-control::placeholder {
    color: #7C95FE;
    opacity: 1;
}

.label {
    color: white;
    margin-bottom: 0px;
    margin-left: 0;
    font-family: poppins;
    font-size: 14px;
}

.exh-form-control {
    color: #3FA9F5 !important;
    font-size: 22px !important;
    font-weight: bold !important;
    font-family: poppinssemibold;
    padding: 0 !important;
}

.token-name {
    color: #E5E5E5 !important;
    font-size: inherit !important;
    font-weight: bold !important;
    font-family: poppins;
    padding: 0 !important;
}

.token-name::placeholder,
.token-name,
.token-name:focus {
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.bridgex-amount {
    color: #E5E5E5 !important;
    font-size: inherit !important;
    font-weight: bold !important;
    font-family: poppins;
    padding: 0 !important;
}

.bridgex-amount::placeholder,
.bridgex-amount,
.bridgex-amount:focus {
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.bridge-text {
    background: #16182F;
}

.exchangePg .wallet .exh-form-control {
    height: auto !important;
    padding: 0;
    font-size: 22px !important;
    line-height: 18.15px;
}

.exchangePg .form-div {
    padding: 10px 20px;
    background: #0D0F11;
}

.option_selector_div .btn-dropdown {
    background-color: #cfcfcf;
    padding: 0;
    border-radius: 13px;
    border: 1px solid;
    width: 40px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.option_selector_div .btn-dropdown img {
    min-width: 12px;
}


.option_selector_div .m-logo {
    height: 20px;
    font-size: 21px;

}

.option_selector_div span {
    color: #E5E5E5 !important;
    margin: 0px 6px;
    font-size: 18.87px;
    font-family: poppinssemibold;
}

.d-flex.justify-content-right .option_selector_div {
    align-items: center;
}

.option_selector_div button.card_dark_btn_link.mg-l-auto {
    padding: 5px 15px;
    align-items: center;
    height: auto;
    background-color: #4C6FFF;
}

.option_selector_div {
    display: flex;
    margin-top: 0px;
}

.cardWrap .option_selector_div {
    flex-direction: column;
}

.option_selector_div .card_dark_btn_link {
    margin-right: 10px !important;
}

.card_dark_btn_div {
    margin-top: 22px;
}

.dropdown-menu-div {
    border-radius: 5px !important;
    border: none !important;
    padding: 0px !important;
    min-width: 105px !important;
}

.arror_down_btn {
    background-color: #3050d1;
    color: white;
    padding: 12px 19px;
    border-radius: 100%;
}

button.btn.btn-white .btn-dropdown:hover .anticon-down svg {
    fill: white;
}

.option_selector_div .btn-dropdown:hover {
    background: #000 !important;
}

.btn-dropdown .anticon-down svg {
    fill: black;
    font-size: 12px;
}

.arror_down_btn:hover {
    background-color: #fff;
}

.arror_down_btn:hover .fa {
    color: #3050d1 !important;
}

.fa-long-arrow-up {
    color: white !important;
    font-size: 19px;
    transform: rotate(180deg);
}

.card_dark_btn {
    background-color: #3050d1;
    color: white;
    border: none !important;
}

.card_dark_btn:hover {
    color: #3050d1 !important;
    background-color: white;
}

div#dashboardPg .exchangePg .card_dark_btn_link {
    background-color: #4C6FFF !important;
    font-size: 14.79px;
    line-height: 14.8px;
}

.dropdown-menu-div button {
    display: block;
    background: none;
    border: none !important;
    padding: 4px 7px;
}

.dropdown-menu-div button span {
    color: black !important;
    margin-left: 7px;
}

.price_main_div {
    margin-top: 20px;
}

.price_main_div h3 {
    color: white;
    font-family: roboto;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: 70px;
    line-height: 1;
    margin-bottom: 40px;
}

.price_main_div .col {
    text-align: center;
    width: 50%;
}

.price_main_div h2 {
    color: white;
    line-height: 55.5px;
    font-size: 55px;
    margin-top: 2px !important;
    font-family: 'roboto';
    border-bottom: 1px dashed #BBBBBB;
    width: 125px;
    margin: auto;
    margin-bottom: 11px;
    padding-bottom: 10px;
    font-weight: 400;
}

.price_main_div p {
    color: white;
    font-size: 20px;
    font-weight: 600;
    font-family: roboto;
    line-height: 1;
    margin-top: 13px;
}

.price_main_div span {
    color: #3FA9F5;
    font-size: 17px;
}

.price_main_div .row {
    margin: 0 -5px;
}

.price_main_div .row>div {
    padding: 0 5px;
}

.right_main_section .col-md-8 {
    width: 70%;
}

.right_main_section .col-md-4 {
    width: 30%;
    padding-left: 15px;
}

.right_main_section .text-center.card_dark_btn_div {
    text-align: center;
}

.card_dark label.label {
    margin-left: 0px;
}

.wallet {
    flex-wrap: wrap;
}

.form_to {
    width: 50%;
}

.mob-text {
    display: none;
}

.wallet .option_selector_div {
    width: 50%;
}

.text-center.arror_down_bx {
    text-align: center;
}

.fa-long-arrow-up:before {
    content: "\f176";
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
}

.exchangePg .title_div.d-flex {
    align-items: center;
    margin-bottom: 15px;
}

.exchangePg .title_div.d-flex h2.title_div_heading {
    margin: 0;
}

@media (min-width: 992px) {
    .tip_tag+p {
        line-height: 28px;
    }
}


@media only screen and (max-width: 1023px) {
    section.ant-layout.site-layout {
        margin-left: 0px;
    }

    .price_main_div .col {
        width: 100%;
        flex: unset;
    }

    .option_selector_div .btn-dropdown img {
        max-width: 10px;
    }

    .card_dark {
        padding: 12px;
    }

    .selected {
        padding-right: 0;
    }
}

@media (min-width:992px) {
    .addLiq .card_dark_btn_div {
        margin-top: 20px;
        bottom: -15px;
        position: absolute;
        left: 0;
        right: 0;
    }
}

@media (min-width:768px) {
    .card_dark_btn_div {
        margin-top: 20px;
        bottom: -15px;
        position: absolute;
        left: 0;
        right: 0;
    }

    .addLiq .card_dark.add_form {
        padding-bottom: 50px;
    }

    .exchangePg .card_dark {
        padding: 20px 20px 50px;
    }
}

@media (max-width:991px) {
    .price_main_div h3 {
        font-size: 16px;
        line-height: 22px;
        font-family: interMedium;
    }

}

@media only screen and (max-width: 768px) {
    button.card_dark_btn_link.mg-l-auto {
        display: none;
    }

    .mob-text {
        display: block;
        color: white;
    }

    .add_form .title_div_input h2 {
        display: none;
    }

    .add_form .title_div_input h2.mob-h2-text {
        display: block;
    }

    .right_main_section {
        margin-left: 0px !important;
        margin-top: 5px !important;
    }

    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .right_main_section .col-md-8 {
        width: 100%;
        max-width: 100%;
        flex: unset;
    }

    .right_main_section .col-md-4 {
        width: 100%;
        padding-left: 0;
        max-width: 100%;
        flex: unset;
    }

    .price_main_div .col {
        width: 50%;
    }

    .card_dark {
        padding: 25px 12px 40px 12px;
    }

    .pad30 {
        padding: 20px;
    }

}


@media (min-width:992px) and (max-width: 1540px) {
    .right_main_section.exchangePg .col-md-8 {
        flex: 0 0 56.666667%;
        max-width: 56.666667%;
    }

    .right_main_section.exchangePg .col-md-4 {
        flex: 0 0 43.33333%;
        max-width: 43.33333%;
    }

    .price_main_div h3 {
        font-size: 18px;
    }

    .price_main_div p {
        font-size: 16px;
    }

    .price_main_div span {
        font-size: 16px !important;
    }

    .proposals-list-div .heading_h2 a {
        font-size: 15px;
    }

    .proposals-list-div .proposals_main_p {
        font-size: 12.7px;
    }

    .proposals-list-div-bottom p {
        font-size: 12px;
    }
}

@media (max-width: 1440px) {
    .price_main_div p {
        font-size: 14px;
    }

    .option_selector_div span {
        font-size: 15px;
    }

    .price_main_div h2 {
        font-size: 45px;
    }

    .price_main_div p,
    .price_main_div span {
        font-size: 14px;
    }

    .exh-form-control,
    .exchangePg .wallet .exh-form-control {
        font-size: 18px !important;
    }

    .label {
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .right_main_section .col-md-4 {
        padding-left: 10px;
    }
}

@media (min-width:992px) and (max-width: 1320px) {
    .price_main_div p {
        font-size: 13px;
    }

    .price_main_div span {
        font-size: 13px !important;
    }
}

@media (max-width: 991px) {

    .right_main_section.exchangePg .col-md-8,
    .right_main_section.exchangePg .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .price_main_div .col {
        width: 50%;
        flex: 1;
    }

    .price_main_div h2 {
        font-size: 35px;
    }
}

@media (max-width:767px) {
    .btnWrapr>a {
        width: 50%;
        text-align: center;
        justify-content: center;
    }

    .right_main_section {
        padding-left: 8px;
        padding-right: 8px;
    }

    .wallet .selected {
        padding-right: 0;
    }

    .form-div .option_selector_div .dropdown.d-flex {
        margin-left: 23px;
    }

    .exchangePg .form-div .option_selector_div .dropdown.d-flex {
        margin-left: 5px;
    }

    .price_main_div .col {
        width: 100% !important;
        flex: unset;
        margin-bottom: 20px;
    }

    .content-section-p {
        font-size: 15px;
    }

    /* .form-div {
        padding: 6px 18px !important
    } */

    .exh-form-control {
        font-size: 16px !important;
    }

    .option_selector_div button.card_dark_btn_link.mg-l-auto {
        padding: 2px 13px;
        font-size: 10px;
        line-height: 1;
    }

    .add_form .title_div_input h2 {
        color: #E5E5E5;
        font-size: 14.44px;
    }

    .option_selector_div span {
        font-size: 13px;
    }

    .option_selector_div span {
        font-size: 12px;
    }

    .dropdown button img {
        max-width: 19px;
    }
}

.bridgefeebullet {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.6416px;
    line-height: 14px;
    color: #9757D7;
}

.purple {
    color: #9757D7;
}

.yellow {
    color: #FFD166;
}

.blue {
    color: #4BC9F0;
}