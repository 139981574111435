.ant-modal-header {
  display: none;
}
body .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8);
}
body .ant-modal-content {
  background: transparent !important;
  color: #fff;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  width: 100%;
}
body .ant-modal-content .ant-modal-close-x {
  position: absolute;
  right: 10px;
}
.popupWrapper {
  background: #10162c;
  color: #fff;
  border-radius: 25px;
  border: 1px solid #355dff;
  font-family: 'Poppins', sans-serif;
}
body .ant-modal-body {
  padding-bottom: 0;
  padding: 0;
}
body .popupWrapper {
  padding: 24px;
  font-size: 16px;
}
body .ant-modal-footer {
  display: none;
}
body .modalHeader h3 {
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-family: poppinssemibold;
}
.contentTkn h3 {
  font-size: 20px;
  line-height: 19.4px;
  margin: 10px 0 0;
  font-family: poppins;
}
body .modalHeader {
  padding: 20px 24px 0;
  color: #fff;
  background: transparent;
  border-bottom: 0;
  border-radius: 0;
  margin: -24px -24px 20px;
}
body .ant-modal-content .ant-modal-close svg {
  fill: #615d7e;
  font-weight: bold;
  height: 22px;
  width: 22px;
  transition: all 500ms ease;
}
body .ant-modal-content .ant-modal-close:hover svg {
  fill: #fff;
}
.tokens {
  /* border: 1px solid #355DFF; */
  /* padding: 20px; */
  /* margin-bottom: 15px; */
  border-radius: 24px;
}
.tokens h1 {
  margin-bottom: 0;
}
.tokens ul.iconImg {
  margin-right: 0;
  margin-left: 15px;
}
body .modal_footer {
  background: #10162c;
  margin: 0 -24px;
  padding: 24px 24px 50px;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}
body .depositBx {
  padding: 24px;
}
.modal_footer > div:last-child {
  border: 0;
}
.modal_footer > .d-flex.dottedBrd {
  font-size: 16px;
}
.btnPoupWrap {
  position: absolute;
  bottom: -19px;
  left: 0;
  border: 0;
  border-radius: 30px;
  margin: auto;
  display: block;
  width: 100%;
  z-index: 55;
}
.outPt {
  font-size: 20px;
  color: #acacac;
  line-height: 1.1;
}
.confirmationWndo {
  border-top: 1px solid #355dff;
}
.confirmationWndo h2,
.cnfirmation h2 {
  padding: 5px 0;
  margin: 0;
  font-family: poppinssemibold;
  font-size: 25px;
  color: #fff;
}
.confirmationWndo h3,
.cnfirmation h3 {
  padding: 0;
  margin: 2px 0 20px;
  color: #fff;
  font-weight: normal;
}
.cnfirmation h3,
.cnfirmation h2 {
  padding: 0;
  margin-bottom: 5px;
}
.confirmationWndo span,
.cnfirmation span {
  font-size: 14px;
  font-family: poppins;
}
.loaderLogo img {
  max-height: 48px;
}
.confirmationWndo h3 {
  margin: 10px 0 20px;
  font-family: poppins;
}
.checkIcon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 2px solid #4c6fff;
  margin: 10px auto 0;
  background: url(../images/iconCheck.svg) no-repeat center center;
  background-size: 20px auto;
  display: inline-block;
}
.borderIn span {
  display: inline-block;
  padding: 6px 16px;
  border-radius: 5px;
  border: 1px solid #8b8b8b;
  background: #171717;
  display: inline-flex;
  align-items: center;
  font-family: poppinssemibold;
}
.borderIn span img {
  margin-left: 5px;
}
.logoMdl img {
  max-height: 49px;
  line-height: 1;
}
.loaderLogo {
  padding: 10px 10px 20px;
}
.cirlcNmber {
  list-style: none;
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 80%;
  max-width: 300px;
  display: flex;
  align-items: center;
}
.cirlcNmber:before {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed rgba(103, 103, 103, 1);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
}
.cirlcNmber li {
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  position: relative;
  z-index: 5;
  background-color: #10162c;
  font-weight: bold;
}

.cirlcNmber .disabled {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(68, 107, 206);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  position: relative;
  z-index: 5;
  background-color: rgba(68, 107, 206);
  font-weight: bold;
}

.cirlcNmber li + li {
  margin-left: auto;
}
.widraBx h3 {
  color: #fff;
  margin: 5px 0 0;
  font-size: 20px;
  line-height: 19.34px;
  font-family: poppins;
  padding: 0;
}
body .m-12 {
  margin: 15px 0;
}
.widraBx .bglightBlu {
  padding: 10px 20px;
  font-size: 32px;
  line-height: 39.34px;
  font-family: poppinssemibold;
}
/* loader spinner */
.loaderIcon {
  height: 120px;
  width: 120px;
  margin: 0 auto 2em;
  overflow: visible !important;
}
.g--circle {
  transform-origin: 60px 60px;
  fill: dodgerblue;
  animation: opacity 1.2s linear infinite;
}
.g--circle:nth-child(12n + 1) {
  animation-delay: -0.1s;
  transform: rotate(-30deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 2) {
  animation-delay: -0.2s;
  transform: rotate(-60deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 3) {
  animation-delay: -0.3s;
  transform: rotate(-90deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 4) {
  animation-delay: -0.4s;
  transform: rotate(-120deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 5) {
  animation-delay: -0.5s;
  transform: rotate(-150deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 6) {
  animation-delay: -0.6s;
  transform: rotate(-180deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 7) {
  animation-delay: -0.7s;
  transform: rotate(-210deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 8) {
  animation-delay: -0.8s;
  transform: rotate(-240deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 9) {
  animation-delay: -0.9s;
  transform: rotate(-270deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 10) {
  animation-delay: -1s;
  transform: rotate(-300deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 11) {
  animation-delay: -1.1s;
  transform: rotate(-330deg) translate(5px, 5px) scale(0.9);
}
.g--circle:nth-child(12n + 12) {
  animation-delay: -1.2s;
  transform: rotate(-360deg) translate(5px, 5px) scale(0.9);
}
.g-circles--v1 .g--circle {
  fill-opacity: 0;
  animation-name: opacity;
}

@keyframes opacity {
  3% {
    fill-opacity: 1;
  }
  75% {
    fill-opacity: 0;
  }
}

.tokens.contentTkn h1 {
  font-size: 28px;
  line-height: 1.4;
}
.contentTkn h3 {
  font-size: 20px;
}
.borderIn {
  text-transform: uppercase;
}
.depositBx .tokens.textLeft h3 {
  font-size: 18px;
}
.depositBx .tokens.textLeft .titles {
  font-size: 22px;
}
.blueBgRound {
  background: rgba(68, 107, 206, 0.55);
  padding: 15px 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 30px;
  font-size: 19px;
  font-family: poppinssemibold;
}
/* end of spinner */
@media (min-width: 581px) {
  .ant-modal {
    min-width: 520px;
  }
  .ant-modal .ant-modal-content {
    min-width: 520px;
  }
}
@media (max-height: 799px) {
  .modalBlue.modalBuy.poppinsFont.whtClr.cncleCwin {
    margin-top: 60px;
  }
}
@media (min-height: 800px) {
  body .ant-modal-root .ant-modal {
    top: 0 !important;
    transform: none;
    padding: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: unset !important;
  }
}
@media (max-width: 1440px) {
  body .modalHeader h3 {
    font-size: 20px;
  }
  .tokens.contentTkn h1 {
    font-size: 21px;
    line-height: 1.2;
  }
  .contentTkn h3 {
    font-size: 16px;
  }
  .outPt {
    font-size: 18px;
  }
  .modal_footer > .d-flex.dottedBrd {
    font-size: 15px;
  }
  .loaderIcon {
    height: 80px;
    width: 80px;
  }
  .loaderLogo img {
    max-height: 36px;
  }
  .confirmationWndo h2,
  .cnfirmation h2 {
    font-size: 20px;
  }
  .confirmationWndo h3 {
    font-size: 16px;
    line-height: 24px;
  }
  .blueBgRound {
    font-size: 17px;
  }
  .depositBx .tokens.textLeft h3,
  .widraBx h3 {
    font-size: 17px;
    line-height: 24px;
  }
  .depositBx .tokens.textLeft .titles {
    font-size: 20px;
  }
  .checkIcon {
    width: 40px;
    height: 40px;
  }
  .widraBx .bglightBlu {
    font-size: 22px;
  }
}

@media (max-width: 810px) {
  .tokens h1 {
    font-size: 22px;
  }
  body .modalHeader h3 {
    font-size: 18px;
  }
  body .tokens h3,
  .outPt {
    font-size: 14px;
  }
  body .depositBx {
    padding: 15px;
  }
  body .popupWrapper {
    padding: 15px 15px 0;
    font-size: 14px;
  }
  body .modalHeader {
    padding: 20px 15px 0;
    margin: -15px -15px 20px;
  }
  body .modal_footer {
    margin: 0 -15px;
  }
  .confirmationWndo h2,
  .cnfirmation h2 {
    font-size: 18px;
  }
  .contentTkn h1 {
    font-size: 25px;
    line-height: 1.1;
  }
  .contentTkn h3 {
    font-size: 20px;
    line-height: 1.1;
  }
  body .tokens h3,
  .outPt {
    font-size: 16px;
    line-height: 20px;
  }
  .modal_footer > .d-flex.dottedBrd {
    font-size: 14px;
  }
  .depositBx .tokens.textLeft h3,
  .blueBgRound {
    font-size: 14px;
    line-height: 20px;
  }
  .depositBx .tokens.textLeft .titles {
    font-size: 16px;
  }
  .cirlcNmber li {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .cirlcNmber {
    width: 70%;
  }
  .widra_pp {
    position: relative;
  }
  .widra_pp .textCenter.mt15 {
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
  }
  body .widra_pp {
    padding-bottom: 30px;
  }
  .widra_pp .textCenter.mt15 .x-large-btn {
    width: auto;
    font-size: 15px;
  }
  .widraBx .bglightBlu {
    font-size: 18px;
  }
  .widraBx h3 {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 580px) {
  .ant-modal {
    min-width: 100%;
    max-width: 500px;
    width: 100% !important;
  }
  .ant-modal .ant-modal-content {
    min-width: 95%;
  }
}
